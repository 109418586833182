<template>
  <div>
    <form novalidate class="md-layout md-alignment-top-center" @submit.prevent="validateForm">
      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <div class="md-title">{{ config.title }} ({{ amountDue | currency }})</div>
          <div v-if="(selected.image1 || selected.image2)">
            <a v-if="selected.image1" :href="selected.image1" target="_blank" style="margin-right: 10px">Photo 1</a>
            <a v-if="selected.image2" :href="selected.image2" target="_blank" style="margin-left: 10px">Photo 2</a>
          </div>
        </md-card-header>
        <md-card-content>
          <md-field :class="getValidationClass('noticeNumber')">
            <label for="noticeNumber">Ref Number</label>
            <md-input name="noticeNumber" id="noticeNumber" v-model="form.noticeNumber" maxlength="50" required readonly />
          </md-field>
          <md-field :class="getValidationClass('plate')">
            <label for="plate">License Plate</label>
            <md-input name="plate" id="plate" v-model="form.plate" maxlength="10" required readonly />
          </md-field>
          <md-field :class="getValidationClass('company')">
            <label for="company">Company Owed To</label>
            <md-input name="company" id="company" v-model="form.company" maxlength="20" required readonly />
          </md-field>
          <md-field md-clearable :class="getValidationClass('firstName')">
            <label for="firstName">First Name</label>
            <md-input name="firstName" id="firstName" v-model="form.firstName" maxlength="30" required />
            <span class="md-error" v-if="!$v.form.firstName.required">First Name is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('lastName')">
            <label for="lastName">Last Name</label>
            <md-input name="lastName" id="lastName" v-model="form.lastName" maxlength="30" required />
            <span class="md-error" v-if="!$v.form.lastName.required">Last Name is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('address1')">
            <label for="address1">Address Line 1</label>
            <md-input name="address1" id="address1" v-model="form.address1" maxlength="50" required />
            <span class="md-error" v-if="!$v.form.address1.required">Address is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('address2')">
            <label for="address2">Address Line 2</label>
            <md-input name="address2" id="address2" v-model="form.address2" maxlength="50" />
          </md-field>
          <md-field md-clearable :class="getValidationClass('city')">
            <label for="city">City</label>
            <md-input name="city" id="city" v-model="form.city" maxlength="30" required />
            <span class="md-error" v-if="!$v.form.city.required">City is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('state')">
            <label for="state">State/Province</label>
            <md-input name="state" id="state" v-model="form.state" maxlength="2" required />
            <span class="md-error" v-if="!$v.form.state.required">State/Province is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('zip')">
            <label for="zip">Postal Code</label>
            <md-input name="zip" id="zip" v-model="form.zip" maxlength="10" required />
            <span class="md-error" v-if="!$v.form.zip.required">Postal Code is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('email')">
            <label for="email">Email</label>
            <md-input type="email" name="email" id="email" v-model="form.email" maxlength="50" required />
            <span class="md-error" v-if="!$v.form.email.required">Email is required</span>
            <span class="md-error" v-else-if="!$v.form.email.email">Not a valid email address</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('cardNumber')">
            <label for="cardNumber">Card Number</label>
            <md-input name="cardNumber" id="cardNumber" v-model="form.cardNumber" maxlength="16" required />
            <span class="md-error" v-if="!$v.form.cardNumber.required">Card Number is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('cardExpDate')">
            <label for="expDate">Exp Date (MM/YYYY)</label>
            <md-input name="expDate" id="expDate" v-model="form.cardExpDate" maxlength="7" required />
            <span class="md-error" v-if="!$v.form.cardExpDate.required">Exp Date is required</span>
          </md-field>
          <md-field md-clearable :class="getValidationClass('cardCvv')">
            <label for="cvv">Card CVV Code</label>
            <md-input name="cvv" id="cvv" v-model="form.cardCvv" maxlength="4" required />
            <span class="md-error" v-if="!$v.form.cardCvv.required">CVV is required</span>
          </md-field>
          <div style="font-weight: bold">
            Pay {{ payAmountToday | currency }} which includes a {{ fee | currency }} convenience fee.
          </div>
          <div v-if="(company && company.custom)"><em>{{ company.custom }}</em></div>
        </md-card-content>
        <md-progress-bar md-mode="indeterminate" v-if="sending" />
        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Submit</md-button>
        </md-card-actions>
      </md-card>
      <md-snackbar :md-active.sync="sendComplete">{{ sendResult }}</md-snackbar>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'payView',
  data () {
    return {
      form: {
        plate: this.$store.getters.selected.plate,
        noticeNumber: this.$store.getters.selected.noticeNumber,
        company: this.$store.getters.selected.company,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        email: null,
        cardNumber: null,
        cardExpDate: null,
        cardCvv: null
      },
      config: {
        title: process.env.VUE_APP_PROMISE_HEADER
      },
      sending: false,
      sendComplete: false,
      sendResult: null
    }
  },
  computed: {
    selected () {
      return this.$store.getters.selected
    },
    company () {
      return this.selected.client
    },
    amountDue () {
      return this.selected.amount
    },
    fee () {
      const c = this.company
      if (!c) return 0.0
      return c.feeEach + (this.amountDue * c.feePcnt)
    },
    payAmountToday () {
      return this.amountDue + this.fee
    }
  },
  methods: {
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    async validateForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.sending = true
        this.sendComplete = false
        this.sendResult = null

        if (await this.takePayment()) {
          this.sendResult = 'Your payment has been sent!'
          setTimeout(() => this.$router.push('/letter'), 200)
        }

        this.sending = false
        this.sendComplete = true
      }
    },
    async takePayment () {
      const token = await this.getCardToken()
      if (token) {
        return await this.submitPayment(token)
      }
      return false
    },
    async getCardToken () {
      try {
        const { data } = await axios({
          method: 'POST',
          url: 'https://checkout.usiopay.com/2.0/GenerateToken',
          data: {
            paymentType: this.company.ccType,
            merchantKey: this.company.ccKey,
            emailAddress: this.form.email,
            cardNumber: this.form.cardNumber,
            expDate: this.form.cardExpDate,
            cvv: this.form.cardCvv
          }
        })

        // console.log(data)
        if (data.status === 'success') {
          return data.token
        } else {
          this.sendResult = data.message
        }
      } catch (error) {
        this.sendResult = error
      }
      return null
    },
    async submitPayment (token) {
      try {
        const { data } = await axios({
          method: 'POST',
          url: 'https://parkprompt.azurewebsites.net/api/webPayment',
          // url: 'http://localhost:7071/api/webPayment',
          data: {
            token: token,
            violationId: this.selected.srcId,
            noticeNumber: this.form.noticeNumber,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            address1: this.form.address1,
            address2: this.form.address2,
            city: this.form.city,
            state: this.form.state,
            zip: this.form.zip
          }
        })

        // console.log(data)
        if (data.success) {
          this.$store.commit('setPaid', true)
          this.$store.commit('setPaidAmount', data.paidAmount)
          return true
        } else {
          this.sendResult = data.message
        }
      } catch (error) {
        this.sendResult = error
      }
      return false
    }
  },
  async mounted () {
    if (!this.$store.getters.selected) {
      this.$router.replace('/search')
    }
  },
  validations: {
    form: {
      noticeNumber: {
        required
      },
      plate: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(10)
      },
      company: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      firstName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      lastName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      address1: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(50)
      },
      address2: {
        maxLength: maxLength(50)
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },
      state: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      zip: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10)
      },
      email: {
        required,
        email,
        maxLength: maxLength(50)
      },
      cardNumber: {
        required,
        numeric,
        minLength: minLength(15),
        maxLength: maxLength(16)
      },
      cardExpDate: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(7)
      },
      cardCvv: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .md-field:last-child {
    margin-bottom: 40px;
  }
</style>
